import {motion} from "framer-motion";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Loader} from "../../../components/loader/Loader";
import {useEffect, useState} from "react";
import {MenuNetwork} from "../components/overview/MenuNetwork";
import {ContentBox} from "../components/overview/ContentBox";
import {FaNetworkWired} from "react-icons/fa";
import NetworkHeader from "../components/NetworkHeader";
import useFetchNetworkConfigById from "../hooks/useFetchNetworkConfigById";
import {NotFound} from "../../../pages/NotFound/NotFound";
import {FiArrowLeft, FiArrowRight, FiChevronLeft} from "react-icons/fi";
import {truncateTextLetter} from "../../../utils/truncateText";

export const DisplayNetwork = ({ isVisible }) => {
    const { id} = useParams();

    const [searchTerm, setSearchTerm] = useState("");
    const [result, setResult] = useState([]);

    const [tabsActive, setTabsActive] = useState(1);
    const [activeMenu, setActiveMenu] = useState(1);

    const [increment, setIncrement] = useState(1);

    const [filters, setFilters] = useState([
        { id: 1, title: "Hidden", checked: false },
        { id: 2, title: "Visible", checked: false }
    ]);

    // LengthofTabFilters
    const [lengthofTabFilters, setLengthofTabFilters] = useState(0);

    const navigate = useNavigate();

    const { data, isLoading, error } = useFetchNetworkConfigById(id, increment);

    useEffect(() => {
        const res = data?.listInfra?.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setResult(res);
    }, [searchTerm]);

    if (error) return <NotFound />
    if (isLoading) return (<Loader content="Your network is being prepared"/>);

    return (
        <motion.article
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}

            transition={{type: "spring", duration: .5, ease: "easeIn"}}
            className="relative"
        >
            <div className="container">
                <div className={`bg-slate-100 flex items-start gap-2 absolute top-0 left-0 w-full h-screen overflow-x-hidden ${activeMenu === null && "overflow-hidden"}`}>
                    {/* LEFT BOX*/}
                    <div className={`bg-white w-1/5 overflow-hidden h-screen`}>
                        <div className="pl-8 flex flex-col items-start justify-center h-20">
                            <h2
                                className="flex items-center text-xl font-medium cursor-pointer hover:text-slate-500"
                                title="Go back to list of networks"
                                onClick={ () => navigate("/list/networks") }
                            > Network Workspace</h2>
                            <p className="text-sm text-slate-500 flex items-center justify-center gap-2" title={data?.name}>{ truncateTextLetter(data?.name, 10) } <span className="text-sm text-slate-400"><FaNetworkWired /></span></p>
                        </div>
                        <hr/>
                        <MenuNetwork
                            activeMenu={activeMenu}
                            setActiveMenu={setActiveMenu}
                            dataTitle={data?.name}
                        />
                    </div>

                    {/* Right BOX*/}
                    <div className={`w-2/5 grow bg-white flex flex-col gap-0 h-full drop-shadow-md border border-l-slate-200`}>
                        {/* header */}
                        { activeMenu !== null &&
                            <NetworkHeader
                                setActiveMenu={setActiveMenu}
                                filters={filters}
                                setFilters={setFilters}
                                dataTitle={data?.name}
                                lengthofTabFilters={lengthofTabFilters}
                            />
                        }
                        <div className={`pl-10 pt-10 relative h-screen ${activeMenu !== null ? `grow overflow-x-auto whitespace-no-wrap pt-0` : "pt-10 overflow-y-auto"} `}>
                            <ContentBox
                                activeTabs={activeMenu}
                                mydata={data}
                                filters={filters}
                                networkId={data.id}
                                setActiveMenu={setActiveMenu}
                                increment={increment}
                                setIncrement={setIncrement}
                                setLengthofTabFilters={setLengthofTabFilters}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </motion.article>
    )
}

