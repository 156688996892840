/*****
 * MODULES
 * */

import {AnimatePresence} from "framer-motion";
import {Route, Routes, useLocation} from "react-router-dom";
import {useEffect} from "react";

/*****
 * LAYOUTS
 * */
import MainLayout from "../components/layouts/MainLayout";
import MessageLayout from "../components/layouts/MessageLayout";
import {LayoutWithoutHeaderFooter} from "../components/layouts/LayoutWithoutHeaderFooter";
import LayoutWithInstallation from "../components/layouts/LayoutWithInstallation";
import {LayoutWithDrawer} from "../components/layouts/LayoutWithDrawer";

/*****
 * PAGES
 * */
import {About} from "../pages/about/About";
import {Home} from "../pages/home/Home";
import {Documentation} from "../pages/doc/Documentation";
import {Faq} from "../pages/doc/Faq";
import { NotFound } from "../pages/NotFound/NotFound";

/*****
 * FEATURES
 * */
// Auth
import {Login} from "../features/auth/page/Login";
import {Register} from "../features/auth/page/Register";
import {ForgotPassword} from "../features/auth/page/ForgotPassword";
import {Logout} from "../features/auth/components/Logout";


// Ppa
import Ppa from "../features/ppa/page/Ppa";
import PpaFormContainer from "../features/ppa/page/PpaFormContainer";

// Catalog
import {CatalogDescriptif} from "../features/catalog/page/CatalogDescriptif";
import {CatalogStepper} from "../features/catalog/page/CatalogStepper";
import {Catalog} from "../features/catalog/page/Catalog";

// News
import {News} from "../features/news/page/News";
import {DetailNews} from "../features/news/page/DetailNews";

// Admin
import {UserManagementContainer} from "../features/admin/pages/UserManagementContainer";
import {RoleManagementContainer} from "../features/admin/pages/RoleManagementContainer";
import {TeamManagementContainer} from "../features/admin/pages/TeamManagementContainer";

// network
import {NetworkListConfig} from "../features/network/page/NetworkListConfig";
// import {Networks} from "../features/network/page/Networks";
import {DisplayNetwork} from "../features/network/page/DisplayNetwork";

// Others
import {CharterSign} from "../features/charter/page/CharterSign";
import TicketList from "../features/tickets/pages/ticketList";
import {Dashboard} from "../features/dashboardUser/pages/Dashboard";
import InfraTaskList from "../features/infraTask/pages/infraTaskList";
// import ResourceTypeList from "../features/resourcetype/ListResourceType";
import UserActivities from "../features/activities/userActivities/pages/userActivities";
import MyNotes from "../features/notes/pages/myNotes";
import {ChangeLog} from "../pages/changelog/ChangeLog";
import {ListResourceType} from "../features/resourcetype/components/ListResourceType";
import {ResourceTypeDashboard} from "../features/resourcetype/components/ResourceTypeDashboard";
import {ListServiceType} from "../features/servicetype/components/ListServiceType";

// Descriptions
import {InstallationsDescriptionContainer} from "../features/description/installations/InstallationsDescriptionContainer";
import {ServicesDescriptionContainer} from "../features/description/services/ServicesDescriptionContainer";
import {isLoginRoute, isPPARoute, isMaintenance} from "../utils/variables";
import PageComingSoon from "../pages/soonpage/PageComingSoon";
import {useFetchUserRole} from "../hooks/useFetchUserRole";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useFetchNetworkConfig} from "../features/network/hooks/useFetchNetworkConfig";
import {Profil} from "../features/user/pages/Profil";
import {Settings} from "../features/user/pages/Settings";
import Maintenance from "../pages/maintenance/Maintenance";
import SuperAdminContainer from '../features/superadmin/page/SuperAdminContainer'



const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const AnimatedRoutes = () => {
    const location = useLocation();


    const user = useSelector(selectUser);
    const role = useFetchUserRole();

    // const { totalItem: totalItemNetworks } = useFetchNetworkConfig();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    });

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>

                { isMaintenance ?
                    <Route path="*" element={<Maintenance />} />
                    :
                    <Route element={<MessageLayout/>}>
                        <Route element={<MainLayout/>}>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/about" exact="true" element={<About/>}/>
                            {/*News*/}
                            <Route path="/news" exact="true" element={<News/>}/>
                            <Route path="/news/:id" exact="true" element={<DetailNews/>}/>

                            <Route path="/docs" exact="true" element={<Documentation/>}/>
                            {/* Route to view the faq */}
                            <Route path="/docs/faq" exact="true" element={<Faq />}/>
                            <Route path="/changelog" exact="true" element={<ChangeLog />}></Route>

                            { (isLoginRoute && user) &&
                                <>
                                    {/* Auth */}
                                    <Route path="/dashboard" exact="true" element={<Dashboard/>}/>

                                    {user.isSuperAdministrator &&
                                        <Route path="superadmin" element={<SuperAdminContainer />} />
                                    }

                                    <Route path="/list">
                                        <Route path="networks" element={<NetworkListConfig />}/>
                                        <Route path="networks/:id" exact="true" element={<DisplayNetwork />}/>
                                    </Route>

                                    {role &&
                                        <Route element={<LayoutWithInstallation />}>
                                            <Route path="/list">
                                                {(user.isSuperAdministrator || role.canResourceTypes) &&
                                                    <Route path="resourcetypes" exact="true" element={<ListResourceType isEdit={false}/>}/>
                                                }

                                                <Route path="tickets" exact="true" element={<TicketList/>}/>
                                                <Route path="infraTask" exact="true" element={<InfraTaskList/>}/>
                                                <Route path="activities/userInfra" exact="true" element={<UserActivities/>}/>
                                                <Route path="notes" exact="true" element={<MyNotes/>}/>

                                                {/*TODO Faire la liste des chartes + CRUD*/}
                                                {(user.isSuperAdministrator || role.canProjects) &&
                                                    <Route path="projects" element={<PageComingSoon />}/>
                                                }
                                            </Route>

                                            {(user.isSuperAdministrator || role.canResourceTypes) &&
                                                <Route path="resourcetypes/:id" exact="true" element={<ResourceTypeDashboard />}/>
                                            }

                                            <Route path="/config">
                                                {(user.isSuperAdministrator || role.canUsers) &&
                                                    <Route path="users" exact="true" element={<UserManagementContainer/>}></Route>
                                                }

                                                {(user.isSuperAdministrator || role.canRoles) &&
                                                    <Route path="roles" exact="true" element={<RoleManagementContainer/>}></Route>
                                                }

                                                {(user.isSuperAdministrator || role.canTeams) &&
                                                    <Route path="teams" exact="true" element={<TeamManagementContainer/>}></Route>
                                                }

                                                {(user.isSuperAdministrator || role.canResourceTypes) &&
                                                    <Route path="resourcetypes" exact="true" element={<ListResourceType isEdit={true}/>}/>
                                                }

                                                {(user.isSuperAdministrator || role.canServiceTypes) &&
                                                    <Route path="servicetypes" exact="true" element={<ListServiceType isEdit={true}/>}/>
                                                }

                                                {/*TODO Faire la liste des chartes + CRUD*/}
                                                {(user.isSuperAdministrator || role.canCharters) &&
                                                    <Route path="charters" element={<PageComingSoon />}/>
                                                }

                                                {/* Services Edition */}
                                                {(user.isSuperAdministrator || role.canServiceTypes) &&
                                                    <Route path="servicetypes/description/:serviceId" exact="true" element={<ServicesDescriptionContainer />}/>
                                                }
                                            </Route>

                                            {/* Installations Edition */}
                                            {(role.isRoleAdmin || user.isSuperAdministrator) &&
                                                <Route path="installation/description" exact="true" element={<InstallationsDescriptionContainer />}/>
                                            }
                                        </Route>
                                    }
                                </>
                            }
                        </Route>
                        {/* Catalog */}
                        <Route path="/catalog" exact="true" element={<CatalogStepper/>}/>
                        <Route path="/catalog/:id/:typecatalog/" element={<Catalog/>}/>
                        <Route path="/catalog/:id/:typecatalog/:idcatalog" element={<CatalogDescriptif/>}/>

                        { isLoginRoute &&
                            <>
                                <Route element={<LayoutWithoutHeaderFooter/>}>
                                    <Route path="/login" exact="true" element={<Login/>}/>
                                    <Route path="/register" exact="true" element={<Register/>}/>
                                    <Route path="/security/forgotpwd" exact="true" element={<ForgotPassword/>}/>
                                    <Route path="/logout" exact="true" element={<Logout />}/>
                                </Route>

                                <Route path="/profil" exact="true" element={<Profil />}/>
                                <Route path="/settings" exact="true" element={<Settings />}/>
                                <Route path="/charter/sign" exact="true" element={<CharterSign/>}/>
                            </>
                        }

                        { isPPARoute ?
                            <Route element={<LayoutWithDrawer/>}>
                                <Route path="/ppa/" element={<Ppa/>}/>
                                <Route path="/ppa/:networkCodeUrl" element={<Ppa/>}/>
                                <Route path="/ppa/:networkCode/:ppaTypeCode" element={<PpaFormContainer />}/>
                                <Route path="/ppa/:networkCode/:ppaTypeCode/:infraCode" element={<PpaFormContainer />}/>
                            </Route>
                            :
                            <Route path="/ppa/" element={<PageComingSoon />}/>
                        }

                        <Route path="*" element={<NotFound />}/>
                    </Route>
                }


            </Routes>
        </AnimatePresence>

    )
}

export default AnimatedRoutes;