import React, {useContext, useEffect, useState} from "react";

// Design
import {Grid} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomizedInputLabel} from "./FormCustomizedInputs";
import {FormLabelTooltip} from "./FormLabelTooltip";
import {IoInformationCircleOutline} from "react-icons/io5";

// Components
import {FormAutoCompleteView} from "./FormAutoCompleteView";

// Context
import {ListTypeFormContext} from "./listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../FormContentForms";

// Custom Functions
import {missingFieldsChecking} from "../../../utils/CustomFunctions"


export const FormAutoCompleteContainer = ({
                               listInput,
                               filteredSelects,

                               name,
                               description,
                               preloadServices,
                               id,
                               inputIndex,
                               values,
                               hide,
                               required,

                               keyLinkValue,
                                isRead
                           }) => {

    const [selectedValues, setSelectedValues] = useState([]);

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id

    /***** SELECT CUSTOM (SINGLE & MULTIPLE VALUES) *****/
    const handleChange = (isMultiple, newValue) => {

        newValue === null && setSelectedValues([]);

        (isMultiple && newValue) && setSelectedValues(newValue.map(option => option.id || option));
        (!isMultiple && newValue) && setSelectedValues(newValue.id);
    };


    const selectValuesFormatting = (selectValuesToFormat) => {
        if (!Array.isArray(selectValuesToFormat)) {

            if(!selectValuesToFormat) {
                return;
            }

            // STRING Value(s) - Single && Multiple (isMultiple: true)
            if (typeof(selectValuesToFormat) === "string") {
                const formatLoadedSelectValues = ((selectValuesToFormat.indexOf(',') > -1) ? selectValuesToFormat.split(',') : selectValuesToFormat.split()).map(item => Number(item));
                setSelectedValues(formatLoadedSelectValues)
            }

            // NUMBER Value - Single only (isMultiple: false)
            if (typeof(selectValuesToFormat) === "number") {
                const formatLoadedSelectValues = [selectValuesToFormat];
                setSelectedValues(formatLoadedSelectValues)
            }

        } else {
            setSelectedValues(selectValuesToFormat)
        }
    }

    const selectValuesSetAfterSave = () => {

        if (localStorage.listTypeItem) {
            const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
            const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)


            // Restore data for remaining lines after a cancel or a line removal
            for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
                if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                    (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                    const loadedSelectValuesFromStorage = listTypeItemFromStorageArray[i].value;
                    selectValuesFormatting(loadedSelectValuesFromStorage);

                }
            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }


    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (values.listInputValues[inputIndex].value !== "") {

                const loadedSelectValues = values.listInputValues[inputIndex].value;
                selectValuesFormatting(loadedSelectValues);

            }
        }
    }

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {
        // if (values.token !== null && values.listInputValues[inputIndex]) {
        if (values.token !== null && values.listInputValues[inputIndex]) {

            values.listInputValues[inputIndex].value = (selectedValues.length !== 0) ? selectedValues : "";

        } else {
            values.listInputValues[inputIndex] = { value : selectedValues };
            Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });
        }
    }, [selectedValues]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, selectedValues.length === 0);
    }, [selectedValues]);



    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && selectValuesSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);


    /***** SELECT PRELOAD SERVICES *****/
    const optionsPreload = preloadServices.map((option) => {
        const ri = option.ri;

        return {
            ri,
            ...option,
        };
    });

    const preloadTypes = [
        "preload_installation",
        "preload_services",
        "preload_teams",
        "preload_resourcetypes",
        "preload_servicetypes",
        "preload_resource",
        "preload_ppa",
    ]

    /***** LABELS *****/
    const AutoCompleteLabel = ({ data }) => {
        return (
            <CustomizedInputLabel shrink id={ `${id}` } sx={{ position: 'relative' }}>
                { data.name }{data.isRequired && " *"}

                { description && (
                    <FormLabelTooltip title={ <div dangerouslySetInnerHTML={{__html: description}}/> }>
                        <span>
                            <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"}/>
                        </span>
                    </FormLabelTooltip>
                )}
            </CustomizedInputLabel>
        )
    }

    return (
        <>
            {(listInput.inputType.code === 'custom') && (
                <Grid item xs={12} lg={6} hidden={listInput.isHidden} className={"self-end"}>
                    <FormControl fullWidth>

                        <AutoCompleteLabel data={listInput} />

                        {filteredSelects?.map((filtered, index) => (
                            <React.Fragment key={index}>

                                {(listInput.id === filtered.id) && (
                                    <FormAutoCompleteView selectData={filtered.select.listSelectOptions}
                                                          labelData={filtered.select.listSelectOptions}
                                                          multiple={listInput.isMultiple}
                                                          selectedValues={selectedValues}
                                                          id={ id }
                                                          fieldUniqueId={ `${fieldUniqueId}` }
                                                          name={ name }
                                                          isRead={isRead}
                                                          handleChange={handleChange}
                                    />
                                )}

                            </React.Fragment>
                        ))}

                    </FormControl>
                </Grid>
            )}

            {(preloadTypes.includes(listInput.inputType.code)) && (
                <Grid item xs={12} lg={6} hidden={listInput.isHidden}>

                    <AutoCompleteLabel data={listInput} />

                    <FormAutoCompleteView selectData={optionsPreload.sort((a, b) => -b.ri.localeCompare(a.ri))}
                                          labelData={preloadServices}
                                          multiple={listInput.isMultiple}
                                          selectedValues={selectedValues}
                                          id={ id }
                                          fieldUniqueId={ `${fieldUniqueId}` }
                                          name={ name }
                                          handleChange={handleChange}
                                          isRead={isRead}

                                          groupedBy={option => option.ri}
                    />
                </Grid>
            )}
        </>
    )
}
